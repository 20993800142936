import React from "react";
import CarouselComponent from "../components/CarouselComponent";

const Main = () => {
  return (
    <div className="main">
      <video 
        className="video-bg"
        autoPlay
        loop
        muted
        playsInline
        preload="auto"
      >
        <source src="https://nubitlan.s3.amazonaws.com/static/855679-hd_1920_1080_30fps.mp4" type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      
      <div className="overlay">
        <div className="content">
          <h1>Nubitlan Security</h1>
          <h2>Awareness, Strategy, Implementation.</h2>
          <h3>Coming soon.</h3>
          <CarouselComponent />
          <div className="links">
            <a href="mailto:jorges@nubitlan.com" className="contact-link">Email Us</a>
            <a href="https://hub.nubitlan.com/" className="demo-link" target="blank">View Demo</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;