 const cyberSecurityStats = [
  {
    "title": "61% of Small and Medium-sized Businesses (SMBs) Experienced a Successful Cyberattack in the Past Year.",
    "description": "61% of SMBs in the US and UK were successfully hit by a cyberattack in the past year.",
    "link":"https://www.blackfog.com/smbs-were-victims-cyberattack/",
    "source": "BlackFog"
  },
  {
    "title": "60% of Small Businesses Close Within 6 Months Following a Cyber Attack.",
    "description": "",
    "link":"https://www.inc.com/joe-galvin/60-percent-of-small-businesses-fold-within-6-months-of-a-cyber-attack-heres-how-to-protect-yourself.html",
    "source": "Inc.com"
  },
  {
    "title": "43% of Data Breaches Affect SMBs, Making Them the Primary Target.",
    "description": "43% of all data breaches are targeted at SMBs, making them the number one target.",
    "link":"https://www.cnbc.com/2021/08/10/main-street-overconfidence-small-businesses-dont-worry-about-hacking.html",
    "source": "CNBC"
  },
  {
    "title": "87% of IT Professionals Reported Experiencing Two or More Successful Attacks in the Last Year.",
    "description": "87% of IT professionals at SMBs reported experiencing two or more successful cyberattacks in the past year.",
    "link":"https://www.techrepublic.com/article/cyberattacks-small-medium-businesses-data-exfiltration/",
    "source": "TechRepublic"
  },
  {
    "title": "82% of Ransomware Attacks Target Small Businesses.",
    "description": "Ransomware is a top cyberthreat to SMBs, with 82% of ransomware attacks targeting small businesses.",
    "link":"https://www.law.umaryland.edu/content/articles/name-659577-en.html",
    "source": "Axios & University of Maryland"
  },
   {
    "title": "Small Businesses Face 350% More Social Engineering Attacks Compared to Larger Enterprises.",
    "description": "Small business employees experience 350% more social engineering attacks, like phishing and vishing, than enterprise employees.",
    "link":"https://www.itopstimes.com/itsec/report-small-businesses-receive-350-more-social-engineering-attacks-than-enterprises-mostly-from-microsoft-impersonators/",
    "source": "IT Ops Times"
  },
  {
    "title": "41% of SMBs Cite Lack of Knowledge as Their Biggest Challenge to Cybersecurity Preparedness.",
    "description": "41% of SMBs cite a lack of knowledge as their biggest challenge to staying prepared against cyber threats.",
    "link":"https://www.blackfog.com/smbs-were-victims-cyberattack/",
    "source": "BlackFog"
  }
];

export default cyberSecurityStats;