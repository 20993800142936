import React from 'react';
import { Carousel } from 'react-bootstrap';
import data from '../api/data';
import '../styles/CarouselComponent.css';

const CarouselComponent = () => {
  return (
    <Carousel fade indicators={true}  prevIcon={null} nextIcon={null}>
      {data.map((stat, index) => (
        <Carousel.Item key={index}>
          <div className="carousel-item-content">
            <Carousel.Caption className="carousel-caption-custom">
              <h3>{stat.title}</h3>
              <p><small>Source: <a href={stat.link} target='blank'>{stat.source}</a></small></p>
            </Carousel.Caption>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default CarouselComponent;
